export const getScrollbarWidth = (element: HTMLElement) => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  // @ts-ignore
  outer.style.msOverflowStyle = 'scrollbar';
  element.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  const node = outer.parentNode;
  node?.removeChild(outer);
  return scrollbarWidth > 16 ? 16 : scrollbarWidth;
};
