import React, { useCallback, useState } from 'react';
import { SupersetClient } from '@superset-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import {
  createFolder,
  deleteFolder,
  setFolders,
  updateDashboardInFolder,
  updateFolder,
} from 'src/components/ListView/CardFolderCollection/actions/foldersActions';
import { RootState } from 'src/dashboard/types';
import { Folder, NewFolder, FolderData } from 'src/views/CRUD/types';
import { getFolderBody } from 'src/dashboard/util/getFolderBody';
import { useNotification } from 'src/hooks/useNotification';
import { UPDATE_TYPES } from 'src/components/ListView/CardFolderCollection/interfaces';
import { useHistory } from 'react-router-dom';
import { Dashboard } from '../../../pages/Dashboards/interfaces';

export type UseFolderResult = {
  /** Папки */
  folders: Array<Folder>;
  /** Индикатор загрузки запросов  */
  isLoadingFolders: boolean;
  /** Загрузка новых папок */
  createNewFolder: (newFolder: NewFolder, successCallback?: () => void) => void;
  /** Обработчик открытия модального окна */
  showModal: (isOpen: boolean) => void;
  /** Индикатор открытости модального окна */
  isModalOpen: boolean;
  /** Удаление папки */
  deleteCurrentFolder: (folderId: number, successCallback?: () => void) => void;
  /** Обновление папки */
  updateCurrentFolder: (
    newFolder: NewFolder,
    successCallback?: () => void,
    updateTye?: UPDATE_TYPES,
  ) => void;
  /** Получение рутовых/под-папок */
  getFolders: (pageSize: number, page?: number, parentId?: number) => void;
  /** Дашборды подпапок */
  dashboards: Array<Dashboard>;
  /** Количество папок */
  count: number;
  /** Обновление дашборда в папке */
  editDashboardInFolder: (dashboardId: number) => void;
  /** Создания дашборда в папку */
  createDashboardInFolder: (folderParentId: number, userId: number) => void;
};
/** Хук обработки папок */
export const useFolder = (): UseFolderResult => {
  const [isLoadingFolders, setIsLoadingFolders] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();
  const { openNotification, openErrorNotification } = useNotification();
  const { folders, dashboards } = useSelector(
    (state: RootState) => state.folders,
  );
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParams({
    folderNames: StringParam,
    folderIds: StringParam,
  });

  const getPathWithoutLastStep = useCallback(value => {
    const splitedValue = value.split('~');
    splitedValue.pop();
    return splitedValue.join('~');
  }, []);

  const resetFolderPath = useCallback(() => {
    if (query.folderNames && query.folderIds) {
      const joinedFolderNames = getPathWithoutLastStep(query.folderNames);
      const joinedFolderIds = getPathWithoutLastStep(query.folderIds);
      setQuery({ folderNames: joinedFolderNames, folderIds: joinedFolderIds });
    }
  }, [query.folderNames, query.folderIds, getPathWithoutLastStep, setQuery]);

  const handleErrorRequest = useCallback(
    body => {
      if (body) {
        const { message } = body;
        if (message) {
          if (typeof message === 'string') {
            return openNotification({
              type: 'error',
              title: t(message),
            });
          }
          const messages = [];

          if (Array.isArray(message)) {
            messages.push(...message);
          } else {
            const values = Object.values(message);
            messages.push(...(values as Array<string>));
          }

          const errorText = (
            <>
              {messages.map(message => (
                <div key={message}>{t(message)}</div>
              ))}
            </>
          );
          return openNotification({
            type: 'error',
            title: errorText,
          });
        }
      }

      return openErrorNotification();
    },
    [openErrorNotification, openNotification],
  );

  const getFolders = useCallback(
    (pageSize, page?, parentId?: number) => {
      setIsLoadingFolders(true);
      const queryParams = `?q={"page_size":${pageSize} ${
        page ? `", page": ${page}` : ''
      }}`;
      const endpoint = parentId
        ? `/api/v1/folder/${parentId}${queryParams}`
        : `/api/v1/folder/${queryParams}`;
      SupersetClient.get({
        endpoint,
      })
        .then(({ json }) => {
          setCount(json.count);
          if (parentId) {
            return dispatch(setFolders(json?.sub_folders, json?.dashboards));
          }
          return dispatch(setFolders(json?.result));
        })
        .catch((error: Response) => {
          error.json().then(body => {
            handleErrorRequest(body);
            resetFolderPath();
          });
        })
        .finally(() => {
          setIsLoadingFolders(false);
        });
    },
    [dispatch, handleErrorRequest, resetFolderPath],
  );

  const showModal = useCallback(isOpen => {
    setIsModalOpen(isOpen);
  }, []);

  const createNewFolder = useCallback(
    (newFolder: NewFolder, successCallback) => {
      const body = getFolderBody(newFolder);
      setIsLoadingFolders(true);
      SupersetClient.post({
        endpoint: `/api/v1/folder/`,
        body: JSON.stringify({
          ...body,
          published: false,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ json }) => {
          dispatch(createFolder(json?.result));
          showModal(false);
          openNotification({
            type: 'success',
            title: t('Folder successfully created'),
          });
          successCallback?.();
        })
        .catch((error: Response) => {
          error.json().then(body => {
            handleErrorRequest(body);
          });
        })
        .finally(() => {
          setIsLoadingFolders(false);
        });
    },
    [dispatch, handleErrorRequest, openNotification, showModal],
  );

  const deleteCurrentFolder = useCallback(
    (folderId: number, successCallback) => {
      setIsLoadingFolders(true);
      SupersetClient.delete({
        endpoint: `/api/v1/folder/${folderId}`,
      })
        .then(() => {
          openNotification({
            type: 'success',
            title: t('Folder successfully deleted'),
          });
          dispatch(deleteFolder(folderId));
          setIsModalOpen(false);
          successCallback?.();
        })
        .catch((error: Response) => {
          error.json().then(body => {
            handleErrorRequest(body);
          });
        })
        .finally(() => {
          setIsLoadingFolders(false);
        });
    },
    [dispatch, handleErrorRequest, openNotification],
  );

  const updateCurrentFolder = useCallback(
    (
      newFolder: NewFolder,
      successCallback = () => {},
      updateTye?: UPDATE_TYPES,
    ) => {
      setIsLoadingFolders(true);
      const body = getFolderBody(newFolder);
      SupersetClient.put({
        endpoint: `/api/v1/folder/${newFolder.id}`,
        body: JSON.stringify({
          ...body,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(({ json }) => {
          openNotification({
            type: 'success',
            title: t(`Folder successfully updated`),
          });
          dispatch(updateFolder(json as FolderData, updateTye));
          setIsModalOpen(false);
          successCallback();
        })
        .catch((error: Response) => {
          error.json().then(body => {
            handleErrorRequest(body);
          });
        })
        .finally(() => {
          setIsLoadingFolders(false);
        });
    },
    [dispatch, handleErrorRequest, openNotification],
  );

  const createDashboardInFolder = useCallback(
    (folderParentId, userId) => {
      SupersetClient.post({
        endpoint: `/api/v1/dashboard/`,
        jsonPayload: {
          dashboard_title: '[ untitled dashboard ]',
          owners: [userId],
          folder: folderParentId,
        },
      })
        .then(({ json }) => {
          history.push(`/superset/dashboard/${json.id}/?edit=true`);
        })
        .catch((error: Response) => {
          error.json().then(body => {
            handleErrorRequest(body);
          });
        });
    },
    [handleErrorRequest, history],
  );

  const editDashboardInFolder = useCallback(
    (dashboardId: number) => {
      SupersetClient.get({
        endpoint: `/api/v1/dashboard/${dashboardId}`,
      })
        .then(({ json }) => {
          dispatch(updateDashboardInFolder(json?.result));
        })
        .catch((error: Response) => {
          error.json().then(body => {
            handleErrorRequest(body);
          });
        });
    },
    [dispatch, handleErrorRequest],
  );

  return {
    folders,
    isLoadingFolders,
    createNewFolder,
    showModal,
    isModalOpen,
    deleteCurrentFolder,
    updateCurrentFolder,
    editDashboardInFolder,
    createDashboardInFolder,
    getFolders,
    // @ts-ignore
    dashboards,
    count,
  };
};
