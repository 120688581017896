import type {
  TablePaginationConfig,
  TableColumnsType,
  TableProps as AntdTableProps,
} from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { memo, PropsWithChildren } from 'react';
import { EmptyPlugOption } from '../EmptyPlug/interfaces';

type EmptyResult = {
  emptyPlug: EmptyPlugOption;
  onClick?: () => void;
  buttonLabel?: string;
};

export interface TableProps<T> {
  data: T[];
  columns: TableColumnsType<T>;
  rowKey: string;
  pagination?: TablePaginationConfig;
  onChange?: AntdTableProps<T>['onChange'];
  isLoading?: boolean;
  sticky?: boolean;
  rowSelection?: TableRowSelection<T>;
  total?: number;
  scrollYHeight?: string;
  emptyResult?: EmptyResult;
  /** Ширина скролла таблицы (типы взяты из antd) */
  scrollXWidth?: string | number | true;
  isHidePagination?: boolean;
}

export const genericMemo: <T>(
  component: T,
  propsAreEqual?: (
    prevProps: PropsWithChildren<T>,
    nextProps: PropsWithChildren<T>,
  ) => boolean,
) => T = memo;
