import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Table as AntdTable } from 'antd';
import { css } from '@superset-ui/core';
import { EmptyPlug } from '../EmptyPlug';
import { genericMemo, TableProps } from './interfaces';
import './styles.less';

function Table<T extends object>(props: TableProps<T>) {
  const {
    columns,
    data,
    isLoading,
    pagination,
    onChange,
    rowSelection,
    rowKey,
    total,
    scrollYHeight,
    emptyResult,
    scrollXWidth,
    isHidePagination = false,
  } = props;

  const { t } = useTranslation();

  const tableScrollYHeight =
    scrollYHeight ||
    (rowSelection?.selectedRowKeys?.length
      ? 'calc(100vh - 424px)'
      : 'calc(100vh - 376px)');

  const emptyLocale = emptyResult
    ? {
        emptyText: (
          <EmptyPlug
            emptyPlug={emptyResult.emptyPlug}
            onClick={emptyResult.onClick}
            buttonLabel={emptyResult.buttonLabel}
          />
        ),
      }
    : {};

  return (
    <AntdTable
      columns={columns}
      dataSource={data}
      loading={{
        spinning: isLoading,
        indicator: <LoadingOutlined className="table-container__loader-icon" />,
        className: 'table-container__loader',
      }}
      pagination={
        isHidePagination
          ? false
          : {
              ...pagination,
              position: ['bottomCenter'],
              showTotal: total => (
                <span className="table-container__pagination-total-text">{`${t(
                  'Total',
                )} ${total}`}</span>
              ),
              size: 'small',
              showSizeChanger: true,
              pageSizeOptions: pagination?.pageSizeOptions || [10, 20, 50, 100],
              locale: { items_per_page: `/ ${t('page')}` },
              total,
            }
      }
      onChange={onChange}
      rowSelection={rowSelection}
      rowKey={record => record[rowKey]}
      className="table-container bi-scrollbar"
      scroll={{ y: tableScrollYHeight, x: scrollXWidth }}
      locale={{
        triggerDesc: t('Click to sort descending'),
        triggerAsc: t('Click to sort ascending'),
        cancelSort: t('Click to cancel sorting'),
        ...emptyLocale,
      }}
      css={css`
        .ant-table-body {
          min-height: ${tableScrollYHeight};
          overflow-y: auto !important;

          table {
            min-height: ${!data.length ? 'inherit' : 'auto'};
          }
        }
      `}
    />
  );
}

export const MemoTable = genericMemo(Table);
