import React, { FC } from 'react';
import './styles.less';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ThumbnailProps } from './interfaces';

export const ThumbnailGallery: FC<ThumbnailProps> = props => {
  const { selectedViz, setSelectedViz, onDoubleClick, vizEntries } = props;

  const { t } = useTranslation();

  const handleSelectChart = (key: string) => setSelectedViz(key);

  return (
    <div className="thumbnail-gallery bi-scrollbar">
      {vizEntries.map(entry => {
        const { key, value: type } = entry;

        return (
          <div
            className={cn('thumbnail-gallery__card', {
              'thumbnail-gallery__card_selected': selectedViz === key,
            })}
            role="button"
            tabIndex={0}
            onClick={() => handleSelectChart(key)}
            onDoubleClick={onDoubleClick}
          >
            <img
              className="thumbnail-gallery__image"
              alt={type.name}
              src={type.thumbnail}
            />
            <div className="thumbnail-gallery__name">{type.name}</div>
            {type.label && (
              <div className="thumbnail-gallery__label">{t(type.label)}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};
