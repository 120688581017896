import Owner from '../../../../types/Owner';

/** Форматирует данные пользователя для селекта */
export const formattingForSelect = (data: Array<Owner>) => {
  if (!data.length) {
    return [];
  }

  return data.map(element => ({
    label: `${element.first_name} ${element.last_name}`,
    value: element.id,
  }));
};
