export enum Sections {
  AllCharts = 'ALL_CHARTS',
  Category = 'CATEGORY',
  Tags = 'TAGS',
  RecommendedTags = 'RECOMMENDED_TAGS',
}

export const DEFAULT_ORDER = [
  'line',
  'big_number',
  'big_number_total',
  'table',
  'pivot_table_v2',
  'echarts_timeseries_line',
  'echarts_area',
  'echarts_timeseries_bar',
  'echarts_timeseries_scatter',
  'pie',
  'mixed_timeseries',
  'dist_bar',
  'area',
  'bar',
  'deck_polygon',
  'time_table',
  'deck_scatter',
  'deck_hex',
  'time_pivot',
  'deck_arc',
  'heatmap',
  'deck_grid',
  'deck_screengrid',
  'treemap_v2',
  'box_plot',
  'sankey',
  'word_cloud',
  'mapbox',
  'kepler',
  'cal_heatmap',
  'rose',
  'bubble',
  'bubble_v2',
  'deck_geojson',
  'horizon',
  'deck_multi',
  'compare',
  'partition',
  'deck_path',
  'graph_chart',
  'world_map',
  'paired_ttest',
  'para',
  'country_map',
];

export const TYPES_WITH_DEFAULT_ORDER = new Set(DEFAULT_ORDER);

export const OTHER_CATEGORY = 'Other';
export const ALL_CHARTS = 'All charts';
export const RECOMMENDED_TAGS = ['Popular', 'ECharts', 'Advanced-Analytics'];
export const MAP_CATEGORY = 'Map';
export const CURRENT_MAP_LIST = ['map-chart'];
