import { isDefined } from '@superset-ui/core';
import { getUrlParam } from '../../../../../utils/urlUtils';
import { MULTIPLE_CHARTS, URL_PARAMS } from '../../../../../constants';
import { DataSource } from '../../../interfaces';
import { exploreUrlReturnType } from './interfaces';

export const exploreUrl = (
  vizType: string | null,
  dataSource?: DataSource,
): exploreUrlReturnType => {
  const dashboardId = getUrlParam(URL_PARAMS.dashboardId);
  let route = 'explore';
  let search = `?viz_type=${vizType}&datasource=${dataSource?.value}`;
  let isNewMap = false;
  if (MULTIPLE_CHARTS.includes(vizType || '')) {
    route = 'create-layer';
    isNewMap = true;
  }
  const url = `/${route}/`;
  if (isDefined(dashboardId)) {
    search += `&dashboard_id=${dashboardId}`;
  }
  const state = { isNewMap };
  return { url, state, search };
};
