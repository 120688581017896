import React, { FC, memo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cls from 'classnames';
import { LoaderProps } from './interfaces';
import './styles.less';

export const Loader: FC<LoaderProps> = memo(props => {
  const { className, position = 'float', size = 's' } = props;
  const icon = <LoadingOutlined spin className={cls('loader__image', size)} />;
  return (
    <Spin
      indicator={icon}
      className={cls('loader', size, position, className)}
    />
  );
});
