import React, { FC, memo } from 'react';
import cn from 'classnames';
import { Input as AntdInput } from 'antd';
import { InputProps } from './interfaces';
import './styles.less';

/** Компонент ввода */
export const Input: FC<InputProps> = memo(props => {
  const {
    className,
    onChange,
    placeholder,
    value,
    prefix,
    size,
    status,
    validationMethods,
    name,
    dataTestId,
    ariaLabel,
    ...otherProps
  } = props;

  return (
    <AntdInput
      {...otherProps}
      className={cn('bi-input', className)}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      prefix={prefix}
      size={size}
      status={status}
      name={name}
      data-test={dataTestId}
      aria-label={ariaLabel}
      {...validationMethods}
    />
  );
});
