import React, { FC } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { CloseButtonProps } from './interfaces';
import './styles.less';

export const CloseButton: FC<CloseButtonProps> = props => {
  const { dataTestId, onClose, position, className } = props;

  const positionButton =
    position === 'left'
      ? 'close-btn-container--left'
      : 'close-btn-container--right';

  return (
    <div className={cn('close-btn-container', positionButton, className)}>
      <Button
        className="close-btn-container__tablet"
        data-test={dataTestId}
        icon={<CloseOutlined />}
        onClick={onClose}
      />
    </div>
  );
};
