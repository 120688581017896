import React, { FC } from 'react';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { Form as AntdForm } from 'antd';
import { FormProps } from './interfaces';

/** Компонент формы */
const FormWrapper: FC<FormProps> = props => <AntdForm {...props} />;

const useForm = <Values,>(form?: FormInstance<Values>) =>
  AntdForm.useForm(form);

const useWatch = <Values,>(dependencies: any, form?: FormInstance<Values>) =>
  AntdForm.useWatch(dependencies, form);

export const Form = Object.assign(FormWrapper, {
  useForm,
  useWatch,
});
