import React, { FC, memo } from 'react';
import './styles.less';
import { Button } from 'src/newComponents/Button';
import { useTranslation } from 'react-i18next';
import { ChartCreationFooterProps } from './interfaces';

export const ChartCreationFooter: FC<ChartCreationFooterProps> = memo(props => {
  const { onCreate, onCancel, isDisabledOnCreate } = props;

  const { t } = useTranslation();

  return (
    <div className="chart-creation-footer">
      <Button
        type="default"
        className="chart-creation-footer__cancel"
        onClick={onCancel}
      >
        {t('Cancel')}
      </Button>
      <Button
        className="chart-creation-footer__create"
        onClick={onCreate}
        isDisabled={isDisabledOnCreate}
      >
        {t('Create')}
      </Button>
    </div>
  );
});
