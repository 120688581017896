import { SupersetClient } from '@superset-ui/core';
import {
  GetUserListOptionsParams,
  ReturnTypeGetUserListOptions,
} from './types';
import { UserOption } from '../../../utils/getSelectUserOptions/types';
import { getSelectUserOptions } from '../../../utils/getSelectUserOptions';

export const getUserListOptions = async (
  params: GetUserListOptionsParams,
): Promise<ReturnTypeGetUserListOptions> => {
  const { accessType, query = '' } = params;

  return SupersetClient.get({
    endpoint: `/api/v1/dashboard/related/${accessType}?q=${query}`,
  })
    .then(response => response.json)
    .then((response: { result: UserOption[]; count: number }) => ({
      data: getSelectUserOptions(
        response.result.filter(item =>
          item.extra.active !== undefined ? item.extra.active : true,
        ),
      ),
      totalCount: response.count,
    }));
};
