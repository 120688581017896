import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import './styles.less';
import Fuse from 'fuse.js';
import type { InputRef } from 'antd';
import { chartLabelWeight, ChartMetadata } from '@superset-ui/core';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/Input';
import { usePluginContext } from '../../../../components/DynamicPlugins';
import Icons from '../../../../components/Icons';
import { nativeFilterGate } from '../../../../dashboard/components/nativeFilters/utils';
import {
  ALL_CHARTS,
  CURRENT_MAP_LIST,
  MAP_CATEGORY,
  OTHER_CATEGORY,
  RECOMMENDED_TAGS,
  Sections,
} from './constants';
import { VizEntry, VizTypeGalleryProps } from './interfaces';
import { vizSortFactor } from './utils/vizSortFactor';
import { doesVizMatchSelector } from './utils/doesVizMatchSelector';
import { ThumbnailGallery } from './components/ThumbnailGallery';
import { ChartListSelector } from './components/ChartListSelector';
import { ChartExample } from './components/ChartExample';

export const ChartVizTypeGallery = (props: VizTypeGalleryProps) => {
  const { selectedViz, onChange, onDoubleClick, denyList } = props;

  const { t } = useTranslation();
  const { mountedPluginMetadata } = usePluginContext();
  const searchInputRef = useRef<InputRef>(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(true);

  const selectedVizMetadata: ChartMetadata | null = selectedViz
    ? mountedPluginMetadata[selectedViz]
    : null;

  const [activeSelector, setActiveSelector] = useState<string>(
    () => selectedVizMetadata?.category || RECOMMENDED_TAGS[0],
  );

  const [activeSection, setActiveSection] = useState<string>(() =>
    selectedVizMetadata?.category
      ? Sections.Category
      : Sections.RecommendedTags,
  );

  const isActivelySearching = isSearchFocused && !!searchInputValue;

  const chartMetadata: VizEntry[] = useMemo(
    () =>
      Object.entries(mountedPluginMetadata)
        .map(([key, value]) => ({ key, value }))
        .filter(({ key }) => !denyList.includes(key))
        .filter(
          ({ value }) =>
            nativeFilterGate(value.behaviors || []) && !value.deprecated,
        )
        .sort((a, b) => vizSortFactor(a) - vizSortFactor(b)),
    [mountedPluginMetadata],
  );

  const chartsByCategory = useMemo(() => {
    const result: Record<string, VizEntry[]> = {};
    chartMetadata.forEach(entry => {
      const category = entry.value.category || OTHER_CATEGORY;

      if (!result[category]) {
        result[category] = [];
      }

      if (category === MAP_CATEGORY && !CURRENT_MAP_LIST.includes(entry.key)) {
        return;
      }

      result[category].push(entry);
    });

    return result;
  }, [chartMetadata]);

  const categories = useMemo(
    () =>
      Object.keys(chartsByCategory).sort((a, b) => {
        // make sure Other goes at the end
        if (a === OTHER_CATEGORY) return 1;
        if (b === OTHER_CATEGORY) return -1;
        // sort alphabetically
        return a.localeCompare(b);
      }),
    [chartsByCategory],
  );

  const chartsByTags = useMemo(() => {
    const result: Record<string, VizEntry[]> = {};
    chartMetadata.forEach(entry => {
      const tags = entry.value.tags || [];
      tags.forEach(tag => {
        if (!result[tag]) {
          result[tag] = [];
        }
        result[tag].push(entry);
      });
    });
    return result;
  }, [chartMetadata]);

  const tags = useMemo(
    () =>
      Object.keys(chartsByTags)
        .sort((a, b) =>
          // sort alphabetically
          a.localeCompare(b),
        )
        .filter(tag => RECOMMENDED_TAGS.indexOf(tag) === -1),
    [chartsByTags],
  );

  const sortedMetadata = useMemo(
    () => chartMetadata.sort((a, b) => a.key.localeCompare(b.key)),
    [chartMetadata],
  );

  // get a fuse instance for fuzzy search
  const fuse = useMemo(
    () =>
      new Fuse(chartMetadata, {
        ignoreLocation: true,
        threshold: 0.3,
        keys: [
          {
            name: 'value.name',
            weight: 4,
          },
          {
            name: 'value.tags',
            weight: 2,
          },
          'value.description',
        ],
      }),
    [chartMetadata],
  );

  const searchResults = useMemo(() => {
    if (searchInputValue.trim() === '') {
      return [];
    }
    return fuse
      .search(searchInputValue)
      .map(result => result.item)
      .sort((a, b) => {
        const aLabel = a.value?.label;
        const bLabel = b.value?.label;
        const aOrder =
          aLabel && chartLabelWeight[aLabel]
            ? chartLabelWeight[aLabel].weight
            : 0;
        const bOrder =
          bLabel && chartLabelWeight[bLabel]
            ? chartLabelWeight[bLabel].weight
            : 0;
        return bOrder - aOrder;
      });
  }, [searchInputValue, fuse]);

  const focusSearch = useCallback(() => {
    // "start searching" is actually a two-stage process.
    // When you first click on the search bar, the input is focused and nothing else happens.
    // Once you begin typing, the selected category is cleared and the displayed viz entries change.
    setIsSearchFocused(true);
  }, []);

  const changeSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => setSearchInputValue(event.target.value),
    [],
  );

  const stopSearching = useCallback(() => {
    // stopping a search takes you back to the category you were looking at before.
    // Unlike focusSearch, this is a simple one-step process.
    setIsSearchFocused(false);
    setSearchInputValue('');
    searchInputRef.current!.blur();
  }, []);

  const handleClickSelector = useCallback(
    (selector: string, sectionId: string) => {
      if (isSearchFocused) {
        stopSearching();
      }
      setActiveSelector(selector);
      setActiveSection(sectionId);
      // clear the selected viz if it is not present in the new category or tags
      const isSelectedVizCompatible =
        selectedVizMetadata &&
        doesVizMatchSelector(selectedVizMetadata, selector);
      if (selector !== activeSelector && !isSelectedVizCompatible) {
        onChange(null);
      }
    },
    [
      stopSearching,
      isSearchFocused,
      activeSelector,
      selectedVizMetadata,
      onChange,
    ],
  );

  const sectionMap = useMemo(
    () => ({
      [Sections.RecommendedTags]: {
        title: t('Recommended tags'),
        selectors: RECOMMENDED_TAGS,
      },
      [Sections.Category]: {
        title: t('Category'),
        selectors: categories,
      },
      // TODO: Вернуть когда доработаем теги
      // [Sections.Tags]: {
      //   title: t('Tags'),
      //   selectors: tags,
      // },
    }),
    [categories, tags],
  );

  const getVizEntriesToDisplay = () => {
    if (isActivelySearching) {
      return searchResults;
    }
    if (activeSelector === ALL_CHARTS && activeSection === Sections.AllCharts) {
      return sortedMetadata;
    }
    if (
      activeSection === Sections.Category &&
      chartsByCategory[activeSelector]
    ) {
      return chartsByCategory[activeSelector];
    }
    if (
      (activeSection === Sections.Tags ||
        activeSection === Sections.RecommendedTags) &&
      chartsByTags[activeSelector]
    ) {
      return chartsByTags[activeSelector];
    }
    return [];
  };

  return (
    <div className="viz-gallery-container">
      <div className="viz-gallery-container__viz-block">
        <div className="viz-gallery-container__list bi-scrollbar">
          <ChartListSelector
            isActivelySearching={isActivelySearching}
            activeSelector={activeSelector}
            activeSection={activeSection}
            handleClickSelector={handleClickSelector}
            sectionMap={sectionMap}
          />
        </div>
        <div className="viz-gallery-container__viz-select">
          <Input
            className="viz-gallery-container__input"
            type="text"
            ref={searchInputRef}
            value={searchInputValue}
            placeholder={t('Search all charts')}
            onChange={changeSearch}
            onFocus={focusSearch}
            suffix={
              <div className="viz-gallery-container__input-suffix">
                {searchInputValue && (
                  <Icons.XLarge iconSize="m" onClick={stopSearching} />
                )}
              </div>
            }
          />
          <ThumbnailGallery
            vizEntries={getVizEntriesToDisplay()}
            selectedViz={selectedViz}
            setSelectedViz={onChange}
            onDoubleClick={onDoubleClick}
          />
        </div>
      </div>
      <div className="viz-gallery-container__gallery-block">
        <ChartExample selectedVizMetadata={selectedVizMetadata} />
      </div>
    </div>
  );
};
