import React, { FC } from 'react';
import { InputPassword } from 'src/newComponents/InputPassword';
import { Input } from 'src/newComponents/Input';
import { FormItem } from '../FormItem';
import { FormLabel } from '../FormLabel';
import { LabeledErrorBoundInputProps, ValidateStatus } from './interfaces';

export const LabeledErrorBoundInput: FC<
  LabeledErrorBoundInputProps
> = props => {
  const {
    className,
    errorMessage,
    helpText,
    isRequired,
    tooltipText,
    hasTooltip,
    label,
    validationMethods,
    id,
    isVisibilityToggle,
    name,
    value,
    placeholder,
    onChange,
  } = props;
  return (
    <FormItem
      className={className}
      validateTrigger={Object.keys(validationMethods)}
      validateStatus={
        errorMessage ? ValidateStatus.Error : ValidateStatus.Success
      }
      help={errorMessage || helpText}
      hasFeedback={!!errorMessage}
      label={
        <FormLabel
          htmlFor={id}
          isRequired={isRequired}
          tooltip={
            hasTooltip && tooltipText ? { title: tooltipText } : undefined
          }
          withoutMargins
        >
          {label}
        </FormLabel>
      }
    >
      {isVisibilityToggle || name === 'password' ? (
        <InputPassword
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          validationMethods={validationMethods}
        />
      ) : (
        <Input
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          validationMethods={validationMethods}
        />
      )}
    </FormItem>
  );
};
