import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChartCreation } from '../ChartCreation';
import { ChartCreationCurtainProps } from './interfaces';
import { ChartCreationFooter } from '../ChartCreationFooter';
import { exploreUrl } from './utils/exploreUrl';
import { Curtain } from '../../../../components/Curtain';
import { DataSource } from '../../interfaces';
import { BI_PORTAL_ID } from '../../../../constants';
import './styles.less';

export const ChartCreationCurtain: FC<ChartCreationCurtainProps> = memo(
  props => {
    const { isOpenChartCreation, handleCloseChartCreate } = props;
    const { t } = useTranslation();

    const history = useHistory();
    const [vizType, setVizType] = useState<string | null>(null);
    const [dataSource, setDataSource] = useState<DataSource>();

    const handleCreateChart = useCallback(() => {
      const { url: pathname, state, search } = exploreUrl(vizType, dataSource);

      history.push({ pathname, search, state });
    }, [dataSource, vizType]);

    return (
      <Curtain
        portalId={BI_PORTAL_ID}
        isOpen={isOpenChartCreation}
        onClose={handleCloseChartCreate}
        containerClassName="chart-creation-curtain"
        childrenClassName="chart-creation-curtain__children"
        title={t('Create a new chart')}
        footer={
          <ChartCreationFooter
            onCreate={handleCreateChart}
            onCancel={handleCloseChartCreate}
            isDisabledOnCreate={!vizType || !dataSource}
          />
        }
      >
        <ChartCreation
          vizType={vizType}
          dataSource={dataSource}
          setVizType={setVizType}
          setDataSource={setDataSource}
          handleCreateChart={handleCreateChart}
        />
      </Curtain>
    );
  },
);
