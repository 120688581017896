import React, { FC, memo } from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Icons from '../../components/Icons';
import { Tooltip } from '../../components/Tooltip';
import { InputPasswordProps } from './interfaces';
import './styles.less';

export const InputPassword: FC<InputPasswordProps> = memo(props => {
  const {
    validationMethods,
    className,
    name,
    value,
    placeholder,
    onChange,
    dataTestId,
    ...otherProps
  } = props;
  const { t } = useTranslation();

  return (
    <Input.Password
      {...otherProps}
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      className={cn('bi-input-password', className)}
      data-test={dataTestId}
      iconRender={isVisible =>
        isVisible ? (
          <Tooltip title={t('Hide password.')}>
            <Icons.EyeInvisibleOutlined
              iconSize="m"
              iconColor="#00000073"
              data-test="icon-closed-eye"
            />
          </Tooltip>
        ) : (
          <Tooltip title={t('Show password.')}>
            <Icons.EyeOutlined
              iconSize="m"
              iconColor="#00000073"
              data-test="icon-eye"
            />
          </Tooltip>
        )
      }
      role="textbox"
      {...validationMethods}
    />
  );
});
