import React, { FC, useEffect, useRef } from 'react';
import './styles.less';
import scrollIntoView from 'scroll-into-view-if-needed';
import cn from 'classnames';
import { SelectorGalleryProps } from './interfaces';

export const SelectorGallery: FC<SelectorGalleryProps> = props => {
  const { selector, sectionId, icon, isSelected, onClick, className } = props;

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!isSelected) {
      return;
    }

    /** Нам нужно дождаться открытия модального окна, а затем прокрутить его, поэтому мы помещаем его в очередь микрозадач. */
    queueMicrotask(() =>
      scrollIntoView(btnRef.current as HTMLButtonElement, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
      }),
    );
  }, [isSelected]);

  return (
    <button
      type="button"
      ref={btnRef}
      key={selector}
      name={selector}
      className={cn('selector-button', className, {
        'selector-button_selected': isSelected,
      })}
      onClick={() => onClick(selector, sectionId)}
    >
      {icon && icon}
      {selector}
    </button>
  );
};
