import { checkIsMobile } from '../checkIsMobile';
import { getScrollbarWidth } from '../getScrollbarWidth';
import { checkIsTablet } from '../checkIsTablet';
import { createAndRemoveElement } from '../createAndRemoveElement';
import { checkIsSafari } from '../checkIsSafari';

/** Функция для получения ширины скроллбара у элемента */
export const getScrollbarSize = (element: HTMLElement) => {
  /* Проверка на планшет или мобильное устройство */
  const isTabletOrMobile = checkIsTablet() || checkIsMobile();
  /** Делаем проверку на то, есть ли у элемента скроллбар, если нет возвращаем 0 */
  if (
    (element.offsetHeight <= window.innerHeight &&
      element.offsetWidth === element.clientWidth) ||
    isTabletOrMobile
  ) {
    return 0;
  }
  /**
     Вычисляем размер скроллбара, если на элементе есть наш класс,
     если нет - то вычисляем размер у боди
     */
  const widthScroll = checkIsSafari() ? 16 : getScrollbarWidth(element);
  return element.className.includes('scrollbar-body')
    ? widthScroll
    : createAndRemoveElement();
};
