import React, { ComponentType, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ChartCreationCurtain } from '../ChartCreationCurtain';
import { WithChartCreationCurtainProps } from './interfaces';

export function withChartCreationCurtain<T>(
  BaseComponent: ComponentType<T & WithChartCreationCurtainProps>,
) {
  return function ComponentWithChartCreationCurtain(props: T) {
    const history = useHistory();

    const [isOpenChartCreation, setIsOpenChartCreation] = useState(false);

    const handleCloseChartCreate = useCallback(() => {
      const searchParams = new URLSearchParams(history.location.search);
      setIsOpenChartCreation(false);

      if (searchParams.has('dashboard_id')) {
        searchParams.delete('dashboard_id');

        history.push({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        });
      }

      if (searchParams.has('datasource')) {
        searchParams.delete('datasource');

        history.push({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        });
      }
    }, [history]);

    return (
      <>
        {isOpenChartCreation && (
          <ChartCreationCurtain
            isOpenChartCreation={isOpenChartCreation}
            handleCloseChartCreate={handleCloseChartCreate}
          />
        )}
        <BaseComponent
          {...props}
          setIsOpenChartCreationCurtain={setIsOpenChartCreation}
        />
      </>
    );
  };
}
