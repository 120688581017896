import { getScrollbarSize } from '../getScrollbarSize';
import { getPaddingRight } from '../getPaddingRight';

export const getWidthScrollAndPadding = (element: HTMLElement) => {
  /* Вычисляем размер ширины скролла, при его отсутствии вернеться 0 */
  const scrollWidth = getScrollbarSize(element);
  /* Получаем padding-right, чтобы суммировать его с scrollWidth */
  const paddingRight = getPaddingRight(element);
  return { scrollWidth, paddingRight };
};
