import { UserOption } from './types';

export const getSelectUserOptions = (userList: UserOption[]) =>
  userList.map(item => {
    const {
      value,
      extra: { username },
      text,
    } = item;

    return {
      value,
      label: username?.length ? `${text} (${username})` : text,
    };
  });
