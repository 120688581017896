import React, { Fragment, FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { Transition } from '@headlessui/react';
import { usePortal } from '../../hooks/usePortal';
import { useRenderHandler } from './hooks/useRenderHandler';
import { Container } from './components/Container';
import { DEFAULT_TEST_ID, CURTAIN_CONTAINER_ID } from './constants';
import { CurtainProps } from './interfaces';
import { styles } from './styles';
import './styles.less';

/** Шторка */
export const Curtain: FC<CurtainProps> = props => {
  const {
    isOpen,
    portalId,
    dataTestId = DEFAULT_TEST_ID,
    onClose,
    position = 'right',
    overlay = 'dark',
    children,
    containerId = CURTAIN_CONTAINER_ID,
    parentId,
    containerClassName,
    childrenClassName,
    title,
    footer,
    isLoading,
  } = props;

  const overlayRef = useRef<HTMLDivElement>(null);

  const isTabletWidth = document.documentElement.clientWidth <= 1023;
  const positionStyles = isTabletWidth ? styles.bottom : styles[position];
  const overlayStyles =
    overlay === 'dark' ? 'bg-overlay-dark' : 'bg-overlay-light';
  const portal = usePortal(portalId);
  const { render, renderOff, renderOn } = useRenderHandler(isOpen);

  return createPortal(
    <Transition
      show={isOpen}
      as="div"
      unmount
      appear
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      beforeEnter={renderOn}
      afterLeave={renderOff}
      data-testid={dataTestId}
      data-analytics={DEFAULT_TEST_ID}
      className={cn(
        'fixed inset-0 z-drawer flex max-screen-m',
        positionStyles.content,
      )}
    >
      <Transition.Child
        as={Fragment}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={overlayRef}
          data-testid={`${dataTestId}.Overlay`}
          data-analytics={`${DEFAULT_TEST_ID}.Overlay`}
          className={cn(
            'absolute inset-0 -z-1',
            !isOpen && 'pointer-events-none',
            overlayStyles,
          )}
          onClick={onClose}
          role="none"
        />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="transform transition ease-in-out duration-500"
        enterFrom={positionStyles.enterFrom}
        enterTo={positionStyles.enterTo}
        leave="transform transition ease-in-out duration-500"
        leaveFrom={positionStyles.leaveFrom}
        leaveTo={positionStyles.leaveTo}
      >
        <Container
          onClose={onClose}
          position={position}
          containerId={containerId}
          parentId={parentId}
          dataTestId={dataTestId}
          overlayRef={overlayRef}
          containerClassName={containerClassName}
          childrenClassName={childrenClassName}
          title={title}
          footer={footer}
          isLoading={isLoading}
        >
          {render && children}
        </Container>
      </Transition.Child>
    </Transition>,
    portal,
  );
};
