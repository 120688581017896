import React, { FC, memo } from 'react';
import './styles.less';
import { SelectorGallery } from '../SelectorGallery';
import { ALL_CHARTS, Sections } from '../../constants';
import { ChartListSelectorProps } from './interfaces';
import { AntdCollapse } from '../../../../../../components';

export const ChartListSelector: FC<ChartListSelectorProps> = memo(props => {
  const {
    isActivelySearching,
    activeSelector,
    activeSection,
    handleClickSelector,
    sectionMap,
  } = props;

  return (
    <>
      <SelectorGallery
        sectionId={Sections.AllCharts}
        selector={ALL_CHARTS}
        isSelected={
          !isActivelySearching &&
          ALL_CHARTS === activeSelector &&
          Sections.AllCharts === activeSection
        }
        onClick={handleClickSelector}
      />
      <AntdCollapse
        className="collapse-list"
        expandIconPosition="right"
        ghost
        defaultActiveKey={Object.keys(sectionMap)}
      >
        {Object.keys(sectionMap).map(sectionId => {
          const section = sectionMap[sectionId];

          return (
            <AntdCollapse.Panel
              className="collapse-list__panel"
              header={section.title}
              key={sectionId}
            >
              {section.selectors.map((selector: string) => (
                <SelectorGallery
                  key={selector}
                  selector={selector}
                  sectionId={sectionId}
                  isSelected={
                    !isActivelySearching &&
                    selector === activeSelector &&
                    sectionId === activeSection
                  }
                  onClick={handleClickSelector}
                />
              ))}
            </AntdCollapse.Panel>
          );
        })}
      </AntdCollapse>
    </>
  );
});
