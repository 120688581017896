import { useState, useCallback } from 'react';

export const useRenderHandler = (defaultSate: boolean) => {
  const [render, setRender] = useState(defaultSate);
  const renderOn = useCallback(() => {
    setTimeout(() => setRender(true), 0);
  }, []);
  const renderOff = useCallback(() => {
    setRender(false);
  }, []);

  return { render, renderOff, renderOn };
};
