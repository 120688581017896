import React, { PropsWithChildren } from 'react';
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import AntdAlert, { AlertProps as AntdAlertProps } from 'antd/lib/alert';
import { useTheme } from '@superset-ui/core';

export type AlertProps = PropsWithChildren<
  AntdAlertProps & { roomBelow?: boolean }
>;

export default function Alert(props: AlertProps) {
  const {
    type = 'info',
    showIcon = true,
    closable = true,
    roomBelow = false,
    children,
  } = props;

  const theme = useTheme();
  const { colors, typography, gridUnit } = theme;
  const { alert, error, info, success } = colors;

  let baseColor = info;
  let AlertIcon = InfoCircleOutlined;
  if (type === 'error') {
    baseColor = error;
    AlertIcon = ExclamationCircleOutlined;
  } else if (type === 'warning') {
    baseColor = alert;
    AlertIcon = InfoCircleOutlined;
  } else if (type === 'success') {
    baseColor = success;
    AlertIcon = CheckCircleOutlined;
  }

  return (
    <AntdAlert
      role="alert"
      showIcon={showIcon}
      icon={<AlertIcon aria-label={`${type} icon`} />}
      closable={closable}
      css={{
        marginBottom: roomBelow ? gridUnit * 4 : 0,
        padding: '8px 32px',
        alignItems: 'flex-start',
        borderRadius: '6px',
        borderColor: baseColor.light3,
        backgroundColor: baseColor.light4,
        '& .ant-alert-icon': {
          fontSize: '24px',
          marginRight: gridUnit * 2,
        },
        '& .ant-alert-message': {
          fontFamily: 'MTSCompactMedium, sans-serif',
          color: colors.grayscale.dark85,
          fontSize: typography.sizes.m,
          fontWeight: typography.weights.medium,
          lineHeight: '20px',
        },
        '& .ant-alert-description': {
          color: colors.grayscale.dark85,
          fontSize: typography.sizes.m,
          fontFamily: 'MTSCompactRegular, sans-serif',
          fontWeight: typography.weights.normal,
          lineHeight: '20px',
        },
        '& .ant-alert-close-icon': {
          fontSize: typography.sizes.m,
          lineHeight: '28px',
        },
      }}
      {...props}
    >
      {children}
    </AntdAlert>
  );
}
