import React, { FC } from 'react';
import cn from 'classnames';
import { InfoTooltip } from 'src/newComponents/InfoTooltip';
import { FormLabelProps } from './interfaces';
import './styles.less';

/** Лейбл для компонентов формы */
export const FormLabel: FC<FormLabelProps> = props => {
  const {
    className,
    htmlFor,
    isRequired,
    children,
    tooltip,
    withoutMargins = false,
  } = props;

  const label = (
    <label
      htmlFor={htmlFor}
      className={cn('form-label', className, {
        'form-label__required': isRequired,
        'form-label--indent': !tooltip && !withoutMargins,
      })}
    >
      {children}
    </label>
  );

  return tooltip ? (
    <div
      className={cn({
        'label-container': !withoutMargins,
        'label-container--flex-start': !withoutMargins && tooltip,
      })}
    >
      {label}
      <InfoTooltip {...tooltip} />
    </div>
  ) : (
    <>{label}</>
  );
};
