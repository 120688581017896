import React, { forwardRef, PropsWithChildren } from 'react';
import cn from 'classnames';
import { useDisabledScroll } from '../../../../hooks/useDisabledScroll';
import { Loader } from '../../../../newComponents/Loader';
import { DEFAULT_TEST_ID } from '../../constants';
import { CloseButton } from '../CloseButton';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { CurtainContainerProps } from './interfaces';
import './styles.less';

export const Container = forwardRef<
  HTMLDivElement,
  PropsWithChildren<CurtainContainerProps>
>((props, ref) => {
  const {
    children,
    dataTestId = DEFAULT_TEST_ID,
    onClose,
    position,
    containerId,
    parentId,
    overlayRef,
    containerClassName = '',
    childrenClassName = '',
    title = '',
    footer,
    isLoading,
  } = props;

  useDisabledScroll({ parentId, overlayRef });

  return (
    <div
      ref={ref}
      data-testid={`${dataTestId}.Content`}
      data-analytics={`${DEFAULT_TEST_ID}.Content`}
      className="curtain-container curtain-container__border-tablet"
    >
      <div
        id={containerId}
        className={cn(
          'bi-scrollbar curtain-container__content curtain-container__border-tablet',
          containerClassName,
        )}
      >
        {isLoading ? (
          <Loader size="l" position="float" />
        ) : (
          <>
            {title && <Header title={title} />}
            <div
              className={cn('curtain-container__children', childrenClassName)}
            >
              {children}
            </div>
            {footer && <Footer footer={footer} />}
          </>
        )}
      </div>
      <CloseButton
        dataTestId={`${dataTestId}.CloseButton`}
        onClose={onClose}
        position={position}
        className="curtain-container--block"
      />
    </div>
  );
});
