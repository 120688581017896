import { NewFolder, SelectData } from 'src/views/CRUD/types';

/** Трансформируем данные из селекта в формат, который необходим для запроса */
const transformSelectData = (selectData: Array<SelectData>) =>
  selectData.map(({ value, id }) => value || id);

/** Получаем body для запроса */
export const getFolderBody = (newFolder: NewFolder) => {
  const body = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const prop in newFolder) {
    if (prop === 'dashboards') {
      const uniqDashboards = new Set(newFolder[prop]);
      body[prop] = Array.from(uniqDashboards);
      continue;
    }
    if (prop === 'id') {
      continue;
    }
    if (newFolder[prop] || typeof newFolder[prop] === 'boolean') {
      if (prop === 'has_access' || prop === 'owners') {
        body[prop] = transformSelectData(newFolder[prop] as Array<SelectData>);
      } else {
        body[prop] = newFolder[prop];
      }
    }
  }

  return body;
};
