import React, { FC, memo } from 'react';
import cn from 'classnames';
import { Tooltip } from 'src/components/Tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InfoTooltipProps } from './interfaces';

export const InfoTooltip: FC<InfoTooltipProps> = memo(props => {
  const {
    color,
    trigger,
    className,
    title,
    placement,
    overlayStyle,
    iconColor = '#00000040',
    iconSize = 14,
    position = 'right',
    ...otherProps
  } = props;

  const positionIndent =
    position === 'right' ? { marginLeft: '4px' } : { marginRight: '4px' };

  return (
    <Tooltip
      {...otherProps}
      title={title}
      placement={placement}
      trigger={trigger}
      overlayStyle={overlayStyle}
      color={color}
      className={cn('info-tooltip', className)}
      {...otherProps}
    >
      <InfoCircleOutlined
        style={{
          color: iconColor,
          fontSize: `${iconSize}px`,
          cursor: 'pointer',
          ...positionIndent,
        }}
      />
    </Tooltip>
  );
});
