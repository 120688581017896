import { RefObject, useEffect } from 'react';
import { removeStyleElement } from './utils/removeStyleElement';
import { addStyleElement } from './utils/addStyleElement';
import { preventEvent } from './utils/preventEvent';

type UseDisabledScrollProps = {
  parentId?: string;
  overlayRef?: RefObject<HTMLDivElement | null>;
};

export const useDisabledScroll = ({
  parentId,
  overlayRef,
}: UseDisabledScrollProps) => {
  /** Если передан id родительского блока берем его или берем body */
  const element = parentId ? document.getElementById(parentId) : document.body;
  useEffect(() => {
    const overlay = overlayRef?.current;
    if (element) {
      /* При открытии убираем у элемента возможность скролиться */
      addStyleElement(element);
    }
    /*
     * Блокирует всплытие событий при тач событиях на overlay,
     * чтобы не давать пользователю взаимодействовать с подложкой под overlay
     */
    if (overlay) {
      overlay.addEventListener('touchmove', preventEvent, { passive: false });
    }
    return () => {
      if (element) {
        /* При закрытии возвращаем элементу возможность скролиться */
        removeStyleElement(element);
      }
      if (overlay) {
        overlay.removeEventListener('touchmove', preventEvent);
      }
    };
  }, [element, overlayRef]);
};
