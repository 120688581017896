export const createAndRemoveElement = () => {
  /** Создаем div, добавляем ему стили для определения скролла */
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.zIndex = '-10000';
  scrollDiv.style.overflow = 'scroll';
  /** Добавляем div к body */
  document.body.appendChild(scrollDiv);
  /** Высчитываем разность ширины, для определения ширины скролла */
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  /** Удаляем div из body */
  document.body.removeChild(scrollDiv);
  return scrollbarSize;
};
