import React, { FC, memo } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import cn from 'classnames';
import { InfoTooltip } from '../InfoTooltip';
import { CheckboxProps } from './interfaces';
import './styles.less';

export const Checkbox: FC<CheckboxProps> = memo(props => {
  const {
    label,
    className,
    onChange,
    checked,
    dataTestId,
    tooltip,
    containerClassName,
    indeterminate = false,
    ...rest
  } = props;

  const checkbox = (
    <AntdCheckbox
      {...rest}
      className={cn('bi-checkbox', className)}
      onChange={onChange}
      checked={checked}
      indeterminate={indeterminate}
      data-test={dataTestId}
    >
      {label}
    </AntdCheckbox>
  );

  return (
    <>
      {tooltip ? (
        <div className={cn('row-container', containerClassName)}>
          {checkbox}
          <InfoTooltip {...tooltip} />
        </div>
      ) : (
        <>{checkbox}</>
      )}
    </>
  );
});
