import { getWidthScrollAndPadding } from '../getWidthScrollAndPadding';
import { styleElement } from '../styleElement';

/** Функция для добавления инлайн стилей на отключение скролла у элемента */
export const addStyleElement = (element: HTMLElement) => {
  const currentElement = getWidthScrollAndPadding(element);
  const currentScrollWidth = currentElement.scrollWidth;
  const currentPaddingRight = currentElement.paddingRight;
  styleElement(element, currentScrollWidth, currentPaddingRight);
};
