import { notification } from 'antd';
import { useCallback, ReactNode } from 'react';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface NotificationParams {
  /** Тип оповещения */
  type: NotificationType;
  /** Заголовок */
  title: React.ReactNode | string;
  /** Описание */
  description?: string;
  /** Кастомное изображение */
  icon?: ReactNode;
}

interface UseNotificationResult {
  /** Функция открытия оповещения */
  openNotification: (props: NotificationParams) => void;
  /** Оповещение об ошибки */
  openErrorNotification: () => void;
}

/** Хук для вызова нотификаций */
export const useNotification = (): UseNotificationResult => {
  const openNotification = useCallback(
    ({ type, title, description, icon }: NotificationParams) => {
      notification.open({
        type,
        message: title,
        description,
        icon,
      });
    },
    [],
  );

  const openErrorNotification = useCallback(() => {
    openNotification({
      type: 'error',
      title: `Oops, something went wrong`,
    });
  }, [openNotification]);

  return { openNotification, openErrorNotification };
};
