import React, { FC } from 'react';
import { Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { FormItemProps } from './interfaces';
import './styles.less';

export const FormItem: FC<FormItemProps> = props => {
  const {
    className,
    colon = false,
    dataTestId,
    tooltipInfo,
    isHidden,
    ...rest
  } = props;

  return (
    <Form.Item
      {...rest}
      className={cn('bi-form-item', className, {
        'bi-form-item--hidden': isHidden,
      })}
      colon={colon}
      tooltip={
        tooltipInfo
          ? {
              title: tooltipInfo,
              icon: <InfoCircleOutlined />,
              placement: 'top',
              overlayInnerStyle: {
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 40,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                fontFamily: 'MTSCompactRegular, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
              },
            }
          : undefined
      }
      data-test={dataTestId}
    />
  );
};
