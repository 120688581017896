import React, { FC } from 'react';
import './styles.less';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChartListExampleProps } from './interfaces';

export const ChartExample: FC<ChartListExampleProps> = props => {
  const { selectedVizMetadata } = props;

  const { t } = useTranslation();

  if (!selectedVizMetadata) {
    return null;
  }

  return (
    <div className="chart-example-container">
      <div className="chart-example-container__info">
        <h3 className="chart-example-container__title">
          {selectedVizMetadata.name}
        </h3>
        <p className="chart-example-container__description bi-scrollbar">
          {selectedVizMetadata.description || t('No description available.')}
        </p>
      </div>

      <div className="chart-example-container__examples-wrapper">
        <h3 className="chart-example-container__title">{t('Examples')}</h3>
        <div className="chart-example-container__examples bi-scrollbar">
          {(selectedVizMetadata.exampleGallery?.length
            ? selectedVizMetadata.exampleGallery
            : [
                {
                  url: selectedVizMetadata.thumbnail,
                  caption: selectedVizMetadata.name,
                },
              ]
          ).map(example => (
            <Image
              key={example.url}
              src={example.url}
              alt={example.caption}
              title={example.caption}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
