import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import './styles.less';
import { ChartCreationProps } from './interfaces';
import { useLoadDataSources } from './hooks/useLoadDataSources';
import getBootstrapData from '../../../../utils/getBootstrapData';
import { FilterPlugins } from '../../../../constants';
import { AsyncSelect } from '../../../../components';
import { ChartVizTypeGallery } from '../ChartVizTypeGallery';
import withToasts from '../../../../components/MessageToasts/withToasts';
import { createErrorHandler } from '../../../../views/CRUD/utils';

const bootstrapData = getBootstrapData();
const denyList: string[] = (
  bootstrapData.common.conf.VIZ_TYPE_DENYLIST || []
).concat(Object.values(FilterPlugins));

const ChartCreationComponent: FC<ChartCreationProps> = memo(props => {
  const {
    vizType,
    setVizType,
    dataSource,
    setDataSource,
    handleCreateChart,
    addSuccessToast,
    addDangerToast,
  } = props;

  const { t } = useTranslation();
  const loadDataSources = useLoadDataSources();

  useEffect(() => {
    const params = querystring.parse(window.location.search)
      ?.datasource as string;

    if (params) {
      loadDataSources(params, 0, 1).then(
        r => {
          const datasource = r.data[0];

          if (typeof datasource.customLabel === 'string') {
            datasource.label = datasource.customLabel;
          }

          setDataSource(datasource);
          addSuccessToast(t('The dataset has been saved'));
        },
        createErrorHandler(errMsg => addDangerToast(errMsg)),
      );
    }
  }, []);

  const onVizTypeDoubleClick = () => {
    if (dataSource?.value && vizType) {
      handleCreateChart();
    }
  };

  return (
    <div className="chart-creation">
      <div className="chart-creation__dataset">
        <div className="chart-creation__select">
          <span className="chart-creation__label">{t('Choose a dataset')}</span>
          <AsyncSelect
            className="chart-creation__input"
            autoFocus
            ariaLabel={t('Dataset')}
            name="select-datasource"
            onChange={setDataSource}
            options={loadDataSources}
            optionFilterProps={['id', 'label']}
            placeholder={t('Choose a dataset')}
            showSearch
            value={dataSource}
          />
        </div>
      </div>
      <ChartVizTypeGallery
        denyList={denyList}
        className="chart-creation__viz-gallery"
        onChange={setVizType}
        onDoubleClick={onVizTypeDoubleClick}
        selectedViz={vizType}
      />
    </div>
  );
});

export const ChartCreation = withToasts(ChartCreationComponent);
