import { useEffect, useRef } from 'react';
import { createRootElement } from './utils/createRootElement';
import { removeRootElement } from './utils/removeRootElement';
import { addRootElement } from './utils/addRootElement';

/**
 * хук для создания портала
 * создает контейнер при отрисовке компонента и вставляет его последним в body
 * лениво инициализирует контейнер для присоединения переданного элемента и переиспользует его без перерисовок
 * следит за тем, чтобы использовалась только одна DOM-нода, к которой будет присоединен контент
 * автоматически удаляет ее, когда компонент с хуком анмаунтится
 */
export const usePortal = (id: string) => {
  const portal = document.getElementById(id);
  const currentRef = useRef({
    existingElem: !!portal,
    elem: portal || createRootElement(id),
  }).current;
  useEffect(() => {
    addRootElement(currentRef.elem, currentRef.existingElem);
    return () => {
      removeRootElement(currentRef.elem, currentRef.existingElem);
    };
  }, [currentRef]);
  return currentRef.elem;
};
