/* eslint-disable no-param-reassign */
export const styleElement = (
  element: HTMLElement,
  scrollWidth: number,
  paddingRight: number,
) => {
  /* Убираем возможность скроллится */
  element.style.overflow = 'hidden';
  /* Блокируем тачскрин */
  element.style.touchAction = 'none';
  /* Добавляем внутренний отступ, чтобы не было дерганья при изчезании скролла */
  element.style.paddingRight = `${scrollWidth + paddingRight}px`;
};
