/** Размеры заглушки */
export enum EmptyPlugSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

/** Позиции контента заглушки */
export enum PositionContent {
  column = 'column',
  row = 'row',
}
