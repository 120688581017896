import React, { FC, memo, ReactNode, useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import emptyListImage from 'src/assets/images/emptyPlugImage.png';
import { EmptyPlugOption, EmptyPlugProps } from './interfaces';
import './styles.less';
import { EmptyPlugSize, PositionContent } from './contants';

export const EmptyPlug: FC<EmptyPlugProps> = memo(props => {
  const {
    emptyPlug,
    onClick,
    buttonLabel,
    position = PositionContent.column,
    customButton,
    size = EmptyPlugSize.DEFAULT,
    className,
    centered = false,
  } = props;
  const { t } = useTranslation();
  const [emptyImage, setEmptyImage] = useState<string | null>(null);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [emptyMessageHelper, setEmptyMessageHelper] = useState<ReactNode>('');

  useLayoutEffect(() => {
    switch (emptyPlug) {
      case EmptyPlugOption.FILTER:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('No data was found as a result of applying filters'));
        setEmptyMessageHelper(
          t('Try resetting or changing your filter settings'),
        );
        break;
      case EmptyPlugOption.DASHBOARDS_CREATOR:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no dashboards created'));
        break;
      case EmptyPlugOption.DASHBOARDS_VIEWER:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no dashboards available'));
        break;
      case EmptyPlugOption.CHARTS:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no charts created'));
        break;
      case EmptyPlugOption.DATASETS:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no datasets created'));
        break;
      case EmptyPlugOption.DATABASES:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no databases created'));
        break;
      case EmptyPlugOption.ALERTS:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no alerts created'));
        break;
      case EmptyPlugOption.REPORTS:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('You have no reports created'));
        break;
      case EmptyPlugOption.DASHBOARD:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('This dashboard does not contain charts'));
        if (buttonLabel) {
          setEmptyMessageHelper(t('Go to the editing panel to add charts'));
        }
        break;
      case EmptyPlugOption.DASHBOARD_FILTERS:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('There are currently no filters available'));
        if (customButton) {
          setEmptyMessageHelper(t('You can add new or change filters'));
        }
        break;
      case EmptyPlugOption.DASHBOARD_CREATE:
        setEmptyImage(emptyListImage);
        setEmptyMessage(
          t('Drag and drop components and charts to the dashboard'),
        );
        setEmptyMessageHelper(
          t(
            'You can create a new chart or use existing ones from the panel on the right',
          ),
        );
        break;
      case EmptyPlugOption.DASHBOARD_EDIT:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('Drag and drop components to this tab'));
        setEmptyMessageHelper(
          t(
            'You can create a new chart or use existing ones from the panel on the right',
          ),
        );
        break;
      case EmptyPlugOption.DASHBOARD_TAB:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('There are no components added to this tab'));
        if (buttonLabel) {
          setEmptyMessageHelper(
            t('You can add the components in the edit mode.'),
          );
        }
        break;
      case EmptyPlugOption.DASHBOARD_EDIT_TAB:
        setEmptyMessage(t('Drag and drop components to this tab'));
        if (onClick) {
          setEmptyMessageHelper(
            <>
              {t('You can')}
              <span
                className="empty-plug__text-underline"
                role="button"
                tabIndex={0}
                onClick={onClick}
              >
                &nbsp;{t('create a new chart')}&nbsp;
              </span>
              {t('or use existing ones from the panel on the right')}
            </>,
          );
        }
        break;
      case EmptyPlugOption.SAMPLES_PANE:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('No samples were returned for this dataset'));
        break;
      default:
        setEmptyImage(emptyListImage);
        setEmptyMessage(t('No data'));
        setEmptyMessageHelper('Please refresh page');
        break;
    }
  }, [emptyPlug, t]);

  return (
    <div
      className={cn('empty-plug', `empty-plug_${size}`, className, {
        'empty-plug--column': position === PositionContent.column,
        'empty-plug--row': position === PositionContent.row,
        'empty-plug--centered': centered,
      })}
    >
      {emptyImage && (
        <div className="empty-plug__image">
          <img src={emptyImage} alt="empty list" />
        </div>
      )}
      {emptyMessage && <h3 className="empty-plug__title">{emptyMessage}</h3>}
      {emptyMessageHelper && (
        <p className="empty-plug__description">{emptyMessageHelper}</p>
      )}
      {buttonLabel && !customButton ? (
        <Button onClick={onClick} className="empty-plug__action" type="primary">
          {buttonLabel}
        </Button>
      ) : null}
      {customButton || null}
    </div>
  );
});
