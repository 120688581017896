import React, { FC, memo } from 'react';
import cn from 'classnames';
import { InputNumber as AntdInputNumber } from 'antd';
import { InputNumberProps } from './interfaces';
import './styles.less';

/** Компонент ввода */
export const InputNumber: FC<InputNumberProps> = memo(props => {
  const {
    className,
    onChange,
    placeholder,
    value,
    prefix,
    size,
    status,
    validationMethods,
    name,
    dataTestId,
    ariaLabel,
    min,
    max,
    isDisabled,
  } = props;

  return (
    <AntdInputNumber
      {...validationMethods}
      className={cn('bi-input-number', className)}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      prefix={prefix}
      size={size}
      status={status}
      name={name}
      data-test={dataTestId}
      aria-label={ariaLabel}
      min={min}
      max={max}
      disabled={isDisabled}
    />
  );
});
