export const parseUrlParams = (params?: string): Record<string, string> => {
  if (!params) {
    return {};
  }

  return params
    .slice(1)
    .split('&')
    .reduce<Record<string, string>>((acc, param) => {
      const [key, value] = param.split('=');
      acc[key] = value;
      return acc;
    }, {});
};
