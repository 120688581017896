import { ChangeEvent } from 'react';

export enum ValidateStatus {
  Error = 'error',
  Success = 'success',
}

export interface LabeledErrorBoundInputProps {
  validationMethods:
    | { onBlur: (event: ChangeEvent<HTMLInputElement>) => void }
    | { onChange: (event: ChangeEvent<HTMLInputElement>) => void };
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errorMessage?: string | null;
  helpText?: string;
  isRequired?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string | null;
  id?: string;
  className?: string;
  isVisibilityToggle?: boolean;
  name?: string;
  value?: string | number;
  placeholder?: string;
  type?: string;
}
