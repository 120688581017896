export const styles = {
  left: {
    enterTo: 'translateX0',
    enterFrom: 'translateX-100',
    leaveTo: 'translateX-100',
    leaveFrom: 'translateX0',
    content: 'content-start',
  },
  right: {
    enterTo: 'translateX0',
    enterFrom: 'translateX100',
    leaveTo: 'translateX100',
    leaveFrom: 'translateX0',
    content: 'content-end',
  },
  bottom: {
    enterTo: 'translateY0',
    enterFrom: 'translateY100',
    leaveTo: 'translateY100',
    leaveFrom: 'translateY0',
    content: 'content-end',
  },
};
