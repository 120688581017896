import { ReactNode } from 'react';
import { EmptyPlugSize, PositionContent } from './contants';

/** типы вариантов заглушки */
export enum EmptyPlugOption {
  /** Стандартная заглушка пустого списка */
  DEFAULT = 'DEFAULT',
  /** Заглушка при фильтрации */
  FILTER = 'FILTER',
  DASHBOARDS_CREATOR = 'DASHBOARDS_VIEWER',
  DASHBOARDS_VIEWER = 'DASHBOARDS_VIEWER',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_FILTERS = 'DASHBOARD_FILTERS',
  DASHBOARD_CREATE = 'DASHBOARD_CREATE',
  DASHBOARD_EDIT = 'DASHBOARD_EDIT',
  DASHBOARD_TAB = 'DASHBOARD_TAB',
  DASHBOARD_EDIT_TAB = 'DASHBOARD_EDIT_TAB',
  CHARTS = 'CHARTS',
  DATASETS = 'DATASETS',
  DATABASES = 'DATABASES',
  ALERTS = 'ALERTS',
  REPORTS = 'REPORTS',
  SAMPLES_PANE = 'SAMPLES_PANE',
}

export interface EmptyPlugProps {
  /** Вариант заглушки */
  emptyPlug?: EmptyPlugOption;
  /** Тайтл для экшен кнопки */
  buttonLabel?: string | null;
  /** Хэндлер для кнопки */
  onClick?: () => void;
  /** Позиционирование контента заглушки */
  position?: PositionContent;
  /** Кастомная кнопка */
  customButton?: ReactNode;
  /** Размер заглушки */
  size?: EmptyPlugSize;
  /** className заглушки */
  className?: string;
  /** Расположение текст по центру */
  centered?: boolean;
}
